import React from "react"
// import { graphql } from "gatsby"
import Image from "../components/imagev2"

import Seo from "../components/seo"
import tw, { css } from "twin.macro"
import { TransitionState } from "../helpers/DummyGatsbyPluginTransitionLink"
import { Spring, animated as a } from "@react-spring/web"
import * as s from './page_inicio.module.css'

import Content from "../components/pwcomps/content"
import Footer from "../components/footer"
import { HeroSliderBackground } from "../components/pwcomps/heroSlider"


const PageInicio = props => {
  const { pageContext } = props
  const lang = pageContext.lang
  const langSettings = pageContext.langSettings
  const menuHasExternalLink = langSettings.menuHasExternalLink
  const pageData = pageContext
  let imagenFija = null
  let sliderFijo = null
  const content = pageData.content.filter(c => {
    if(c.type === 'imagen' && imagenFija === null && !!c.data.checkbox1){
      imagenFija = c.data?.blocks[0]
      return false
    }
    if(c.type === 'hero_slider' && sliderFijo === null){
      sliderFijo = c
    }
    return true
  })
  imagenFija = sliderFijo !== null ? null : imagenFija
  return (
    <>
      <Seo
        pageContext={pageContext}
      />

      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus)
          const isExiting = transitionStatus === "exiting"
          return (
            <>
              {sliderFijo && (
                <Spring
                  to={{
                    opacity: isMounting ? 1 : 0,
                  }}
                  config={
                    {
                      // duration: seconds * 1000,
                    }
                  }
                >
                  {styles => (
                    <a.div
                      style={styles}
                      className={s.fixedImage}
                    >
                      <HeroSliderBackground content={sliderFijo} />
                    </a.div>
                  )}
                </Spring>
              )}
              {imagenFija && (
                <Spring
                  to={{
                    opacity: isMounting ? 1 : 0,
                  }}
                  config={
                    {
                      // duration: seconds * 1000,
                    }
                  }
                >
                  {styles => (
                    <a.div
                      style={styles}
                      className={s.fixedImage}
                    >
                      <Image
                        objectFit={"cover"}
                        imageTablet={imagenFija.image}
                        imageMobile={imagenFija.image_mobile}
                        widths={["880px", "980px", "1200px"]}
                      />
                    </a.div>
                  )}
                </Spring>
              )}
              <div css={menuHasExternalLink ? cssSpacerExternal : cssSpacer} />
              <div css={tw`px-5 md:px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0  font-obbody overflow-x-visible`}
                >
                  <article
                    // css={tw`mt-105vh md:mt-100vh  bg-fondo1 md:bg-transparent`}
                  >
                    {/* <Spring
                      to={{
                        opacity: isMounting ? 1 : 0,
                      }}
                      config={
                        {
                          // duration: seconds * 1000,
                        }
                      }
                    >
                      {styles => (
                        <a.div
                          style={styles}
                          css={tw`absolute w-70vw max-w-xl md:max-w-none md:w-auto h-auto top-60vh md:top-60vh `}
                        >
                          <div
                            css={tw`relative -ml-2 md:-ml-5 px-2 md:px-4 bg-fondo1Video dark:bg-gris5Video`}
                          >
                            <div
                              css={tw`absolute inset-y-0 w-50vw -left-50vw bg-fondo1Video dark:bg-gris5Video`}
                            ></div>

                          </div>
                        </a.div>
                      )}
                    </Spring> */}
                    <div>
                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0,
                        }}
                        config={
                          {
                            // duration: seconds * 1000,
                          }
                        }
                      >
                        {styles => (
                          <a.div style={styles}>
                              <Content
                                contents={content}
                                lang={pageData.lang}
                                pageRuta={pageData.ruta}
                              />
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={props.pageContext.langSettings} />
            </>
          )
        }}
      </TransitionState>
    </>
  )
}

// const sloganTextTw = tw`inline-block -ml-2 md:ml-0 pl-2 md:pl-0 pr-2 md:pr-4 py-1 md:py-3 text-2xl md:text-5xl uppercase font-obcorp font-light whitespace-nowrap bg-fondo1 md:bg-transparent`

export default PageInicio

// const cssSvgDark = css`
//   html.dark & path {
//     fill: #fff;
//   }
// `
const cssSpacerExternal = css`
  height: 90px;
  @media (min-width: 924px) {
    height: 222px;
  }
`
const cssSpacer = css`
height: 90px;
@media (min-width: 768px) {
  height: 222px;
}
`